<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      color="light-blue"
      icon="mdi-account-tie"
      title="Formaciones"
      class="px-5 py-3"
    >
      <v-data-table
        v-if="formacioneslist"
        :headers="headers"
        :items="formaciones"
        :search="search"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            />
            <v-spacer />
            <v-btn
              color="primary"
              fab
              dark
              small
              class="mb-2"
              @click="addformaciones()"
              v-on="on"
            >
              <v-icon
                dark
              >
                mdi-plus
              </v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.acciones="{ item }">
          <td>
            <v-icon
              small
              class="mr-2"
              color="success"
              @click="editItem(item)"
            >
              mdi-pencil-outline
            </v-icon>
            <v-icon
              small
              color="error"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <addFormaciones
        :edited-item="datos"
        :edited-index="editedIndex"
        @return-from-form="returnFromForm"
      />
    </base-material-card>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import addFormaciones from './addFormaciones.vue'
  export default {
    name: 'FormacionesTable',
    components: {
      addFormaciones,
    },
    data: vm => {
      return {
        search: '',
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        datos: {},
        editedIndex: -1,
        on: false,
        headers: [
          {
            text: 'Tipo',
            align: 'left',
            sortable: true,
            value: 'tipo',
          },
          /* {
            text: 'Idioma',
            align: 'left',
            sortable: true,
            value: 'idioma',
          }, */
          {
            text: 'Fecha (Desde)',
            align: 'left',
            sortable: true,
            value: 'fecha',
          },
          {
            text: 'Fecha (Hasta)',
            align: 'left',
            sortable: true,
            value: 'fecha_hasta',
          },
          {
            text: 'Total de Horas',
            align: 'left',
            sortable: true,
            value: 'total_horas',
          },
          {
            text: 'Descripción',
            align: 'left',
            sortable: true,
            value: 'descripcion',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
          },
        ],
        emailRules: [
          v => !!v || 'EL correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo valido',
        ],
      }
    },
    computed: {
      formacioneslist: {
        get () {
          return this.$store.state.formaciones.formacioneslist
        },
      },
      formaciones: {
        get () {
          return this.$store.state.formaciones.formaciones
        },
        set (value) {
          return this.$store.commit('formaciones/setFormaciones', value)
        },
      },
      loading: {
        get () {
          return this.$store.state.formaciones.loadingForm
        },
      },
    },
    mounted () {
      this.$store.dispatch('formaciones/getFormaciones')
    },
    methods: {
      addformaciones () {
        this.datos = {}
        this.editedIndex = -1
        this.$store.commit('formaciones/setRegister', true)
      },
      editItem (item) {
        this.editedIndex = this.formaciones.indexOf(item)
        this.datos = Object.assign({}, item)
        this.$store.commit('formaciones/setRegister', true)
      },
      deleteItem (item) {
        this.$confirm('Está seguro que desea eliminar la formación?', {
          buttonTrueText: 'Si',
          buttonFalseText: 'No',
        })
          .then(res => {
            if (res) {
              this.$store.dispatch('formaciones/deleteFormacion', item.id)
                .then((response) => {
                  Vue.notify({
                    group: 'loggedIn',
                    text: response.data.success,
                    type: 'teal accent-3',
                    duration: 5000,
                  })
                })
                .catch(err => {
                  // eslint-disable-next-line
                  console.log(err)
                  Vue.notify({
                    group: 'loggedIn',
                    text: 'No se pudo eliminar en este momento',
                    type: 'red accent-2',
                    duration: 5000,
                  })
                },
                )
            }
          })
      },
      returnFromForm () {
        this.editedIndex = -1
      },
    },

  }
</script>

<style scoped>
.v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
