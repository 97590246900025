<template>
  <v-card v-if="formacionesadd">
    <template>
      <!-- <v-subheader>Datos Personales</v-subheader> -->
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <input
            v-model="editedItem.id"
            type="hidden"
          >
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-select
                v-model="editedItem.tipo"
                :items="itemsPreformacion"
                label="Tipo"
                :rules="[v => !!v || 'Campo requerido']"
              />
            </v-col>
            <!--
              <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-select
                v-model="editedItem.idioma"
                :items="itemsIdiomas"
                label="Idioma"
                :rules="[v => !!v || 'Campo requerido']"
              />
            </v-col>
            -->
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                v-model="editedItem.total_horas"
                label="Total de Horas"
                :rules="[v => !!v || 'Campo requerido']"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-menu
                ref="menuFn"
                v-model="menuFn"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    ref="dateFn"
                    v-model="editedItem.fecha"
                    label="Fecha (Desde)"
                    hint="DD/MM/YYYY"
                    persistent-hint
                    prepend-icon="mdi-calendar-month"
                    v-bind="attrs"
                    :rules="[
                      (v) => !!v || 'Campo requerido',
                      (v) =>
                        !dateFn_1_Backup ||
                        dateFnBackup <= dateFn_1_Backup ||
                        'La fecha de Inicio debe ser menor a la fecha de Fin',
                    ]"
                    @blur="dateFn = parseDate(editedItem.fecha)"
                  />
                </template>
                <v-date-picker
                  v-model="dateFn"
                  locale="es"
                  show-current="false"
                  @input="menuFn = false"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-menu
                ref="menuFn_1"
                v-model="menuFn_1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    ref="dateFn_1"
                    v-model="editedItem.fecha_hasta"
                    label="Fecha (Hasta)"
                    hint="DD/MM/YYYY"
                    persistent-hint
                    prepend-icon="mdi-calendar-month"
                    v-bind="attrs"
                    :rules="[
                      (v) => !!v || 'Campo requerido',
                      (v) =>
                        !dateFn ||
                        dateFn_1_Backup >= dateFnBackup ||
                        'La fecha de Fin debe ser mayor a la fecha de Inicio',
                    ]"
                    @blur="dateFn_1 = parseDate(editedItem.fecha_hasta)"
                  />
                </template>
                <v-date-picker
                  v-model="dateFn_1"
                  locale="es"
                  show-current="false"
                  @input="menuFn_1 = false"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                v-model="editedItem.horario_imparticion"
                label="Horario de Impartición:"
                :rules="[v => !!v || 'Campo requerido']"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="editedItem.plazas"
                label="Cantidad de Plazas"
                :rules="[
                  v => !!v || 'Campo requerido',
                  v => v > 0 || 'El valor de las plazas debe ser mayor a 0',
                ]"
                type="number"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="editedItem.descripcion"
                label="Descripción"
                :rules="[v => !!v || 'Campo requerido']"
              />
            </v-col>
          </v-row>
          <v-row class="mb-4">
            <v-col
              cols="12"
              sm="6"
              md="10"
            >
              <v-file-input
                v-model="editedItem.fichero_cargado"
                label="Cargar un documento"
                accept="application/pdf"
                hint="El archivo no debe ser mayor a 7MB. Si carga un nuevo documento reemplazará al anterior."
              />
            </v-col>
            <v-col
              v-if="editedIndex >= 0"
              cols="12"
              sm="12"
              md="12"
            >
              <v-card
                max-width="100%"
                min-height="100%"
                class="mx-auto overflow-y-auto"
              >
                <v-toolbar
                  dark
                  dense
                >
                  <v-toolbar-title>Documento cargado</v-toolbar-title>

                  <v-spacer />
                </v-toolbar>

                <v-list
                  v-if="editedItem.fichero !== null"
                  two-line
                >
                  <v-list-item>
                    <v-list-item-action>
                      <v-btn
                        color="primary"
                        class="mr-2"
                        :loading="loadingDownload"
                        @click="downloadFile(editedItem.id, editedItem.fichero)"
                      >
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title v-text="editedItem.fichero" />
                      <v-list-item-subtitle
                        class="text--primary"
                      />
                      <!-- <v-list-item-subtitle v-text="item.exp" /> -->
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                        color="error"
                        class="mr-2"
                        @click="removeFile(editedItem.id)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-card-text v-else>
                  <p
                    class="text-center text-h3 mt-5 grey--text"
                  >
                    Aún no se ha cargado ningún documento.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-row>
              <v-spacer />
              <v-btn
                color="#F6F6F6"
                style="color:black;"
                @click="volver"
              >
                {{ $t("close") }}
              </v-btn>
              <v-btn
                class="mr-4"
                color="primary"
                :loading="loading"
                @click="submit"
              >
                {{ $t("save") }}
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-container>
    </template>
    <v-container
      v-if="editedIndex >= 0"
    >
      <h2 class="title_table mt-5">
        Plazas e Inscritos
      </h2>
      <v-col>
        <v-container>
          <v-data-table
            v-if="registerlist"
            :headers="headersUsuarias"
            :items="registros"
            loading-text="Cargando... Espere por favor"
            disable-pagination
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar
                flat
                color="white"
              >
                <v-row
                  align="center"
                  class="list px-3 mx-auto"
                >
                  <v-col
                    cols="10"
                    sm="8"
                  >
                    <v-text-field
                      v-model="search_usuarias"
                      label="Buscar por Nombre, Tipo de documento, Número de documento, Teléfono o Fecha"
                      hint="Si desea ver nuevamente la lista completa, presione el botón de Buscar, sin ningún texto en la caja."
                    />
                  </v-col>
                  <v-col
                    cols="2"
                    sm="4"
                  >
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="primary"
                      @click="
                        page = 1;
                        initialize();
                      "
                    >
                      <v-icon dark>
                        mdi-magnify
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-spacer />
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.fecha_inicio }}</td>
                <td>{{ item.tipo_documento }}</td>
                <td>{{ item.nro_documento }}</td>
                <td>{{ item.nombre_apellido }}</td>
                <td>{{ item.telefono }}</td>
                <td>
                  <v-btn
                    color="primary"
                    fab
                    dark
                    small
                    class="mb-2"
                    @click="addFormacionUsuarias(item)"
                  >
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <template v-slot:header.fecha_inicio>
              <button @click="ordenFecha(sort)">
                Fecha
                <span
                  v-if="sort === 'ASC'"
                  class="mdi mdi-sort-calendar-ascending"
                />
                <span
                  v-else
                  class="mdi mdi-sort-calendar-descending"
                />
              </button>
            </template>
          </v-data-table>
          <v-row
            v-if="registerlist"
            align="center"
            class="list px-3 mx-auto"
          >
            <v-col
              cols="12"
              sm="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="10"
                >
                  <v-pagination
                    v-model="page"
                    :length="totalPages"
                    total-visible="7"
                    next-icon="mdi-menu-right"
                    prev-icon="mdi-menu-left"
                    @input="handlePageChange"
                  />
                </v-col>
                <v-col
                  cols="4"
                  sm="2"
                >
                  <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerPageArray"
                    label="Registros por página"
                    @change="handleitemsPerPageChange"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!--           <v-table class="table_add_formacion">
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Calories
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in desserts"
                :key="item.name"
              >
                <td>{{ item.name }}</td>
                <td>{{ item.calories }}</td>
              </tr>
            </tbody>
          </v-table> -->
        </v-container>
      </v-col>
      <v-row>
        <v-col>
          <v-container>
            <h3 class="text-center title_table">
              Lista de Inscritos
            </h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">
                      Nombre Completo
                    </th>
                    <th class="text-center">
                      Tipo de Identificación
                    </th>
                    <th class="text-center">
                      Identificación
                    </th>
                    <th class="text-center">
                      Teléfono
                    </th>
                    <th class="text-center">
                      Fecha de Inscripción
                    </th>
                    <th class="text-center">
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in usuariasInscritas"
                    :key="item.name"
                  >
                    <td>{{ item.nombre_apellido }}</td>
                    <td>{{ item.tipo_documento }}</td>
                    <td>{{ item.nro_documento }}</td>
                    <td>{{ item.telefono }}</td>
                    <td>{{ item.fecha }}</td>
                    <td class="text-center">
                      <v-icon
                        small
                        color="error"
                        @click="removeFormacionUsuaria(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
        </v-col>
      </v-row>
      <v-spacer />
      <v-row>
        <v-col>
          <v-container>
            <h3 class="title_table">
              Lista En espera
            </h3>
            <v-simple-table>
              <template>
                <thead>
                  <tr>
                    <th class="text-center">
                      Nombre Completo
                    </th>
                    <th class="text-center">
                      Tipo de Identificación
                    </th>
                    <th class="text-center">
                      Identificación
                    </th>
                    <th class="text-center">
                      Teléfono
                    </th>
                    <th class="text-center">
                      Fecha de Inscripción
                    </th>
                    <th class="text-center">
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in usuariasEnEspera"
                    :key="item.name"
                  >
                    <td>{{ item.nombre_apellido }}</td>
                    <td>{{ item.tipo_documento }}</td>
                    <td>{{ item.nro_documento }}</td>
                    <td>{{ item.telefono }}</td>
                    <td>{{ item.fecha }}</td>
                    <td class="text-center">
                      <v-icon
                        small
                        color="error"
                        @click="removeFormacionUsuaria(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  import Vue from 'vue'
  export default {
    name: 'AddFormaciones',
    props: {
      editedItem: {
        type: Object,
        required: true,
      },
      editedIndex: {
        type: Number,
        default: 0,
      },
    },
    data: vm => {
      return {
        registros: [],
        search_usuarias: '',
        tab: null,
        valid: true,
        dateFn: new Date().toISOString().substr(0, 10),
        dateFn_1: new Date().toISOString().substr(0, 10),
        dateFnBackup: null,
        dateFn_1_Backup: null,
        menuFn: false,
        menuFn_1: false,
        search: '',
        reset: false,
        itemsPreformacion: [
          'Formaciones',
          'Cursos y Talleres',
        ],
        usuariasInscritas: [],
        usuariasEnEspera: [],
        /* itemsIdiomas: [
          'Inglés',
          'Español',
        ], */
        emailRules: [
          v => !!v || 'EL correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo valido',
        ],
        /* edad: [
          v => {
            if (!isNaN(parseFloat(v)) && v >= 0) return true
            return 'Ingresa un número'
          },
        ], */
        headersUsuarias: [
          {
            text: 'Fecha',
            align: 'left',
            sortable: false,
            filterable: false,
            value: 'fecha_inicio',
          },
          {
            text: 'Tipo ID',
            align: 'left',
            sortable: false,
            filterable: false,
            value: 'tipo_documento',
          },
          {
            text: 'Identificación',
            align: 'left',
            sortable: false,
            filterable: false,
            value: 'nro_documento',
          },
          {
            text: 'Nombre completo',
            align: 'left',
            sortable: false,
            filterable: false,
            value: 'nombre_apellido',
          },
          {
            text: 'Teléfono',
            align: 'left',
            sortable: false,
            filterable: false,
            value: 'telefono',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
            filterable: false,
          },
        ],
        // Paginacion
        sort: 'ASC',
        page: 1,
        totalPages: 0,
        itemsPerPage: 5,
        itemsPerPageArray: [5, 10],
      }
    },
    computed: {
      formacionesadd: {
        get () {
          return this.$store.state.formaciones.formacionesadd
        },
      },
      formaciones: {
        get () {
          return this.$store.state.formaciones.formaciones
        },
      },
      loading: {
        get () {
          return this.$store.state.formaciones.loadingFormSave
        },
      },
      loadingDownload: {
        get () {
          return this.$store.state.formaciones.loadingDownload
        },
      },
      registerlist: {
        get () {
          return this.$store.state.registros.registerlist
        },
      },
    },
    watch: {
      dateFn (val) {
        this.editedItem.fecha = this.formatDate(this.dateFn)
        this.dateFnBackup = val
        /* console.log(this.editedItem.fecha) */
        /* const tempData = this.editedItem.fecha
        console.log(tempData, 'Primera fecha capturada') */
      },
      dateFn_1 (val) {
        this.editedItem.fecha_hasta = this.formatDate(this.dateFn_1)
        this.dateFn_1_Backup = val
        /* console.log(this.editedItem.fecha_hasta) */
        /* const tempData1 = this.editedItem.fecha_hasta */
        /* console.log(tempData1, 'Segunda fecha capturada')
        console.log(this.dateFn) */
      },
      editedIndex (val, oldval) {
        // Esta condicion significa que nos encontramos en la edición de formaciones
        if (val >= 0 || oldval >= 0) {
          this.initialize()
          this.$store
            .dispatch('formaciones/getFormacionUsuarias', this.editedItem.id)
            .then(response => {
              this.actualizarListadoDeInscritos(response)
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
    },
    async beforeMount () {
      await this.$store.commit('formaciones/setRegister', false)
    },
    methods: {
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      volver () {
        this.$emit('return-from-form')
        this.$refs.form.reset()
        this.$store.commit('formaciones/setRegister', false)
      },
      async downloadFile (idFormacion, nombreFichero) {
        var response = await this.$store.dispatch('formaciones/downloadFile', idFormacion)
        this.forceFileDownload(response, nombreFichero)
      },
      forceFileDownload (response, nombre) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nombre)
        document.body.appendChild(link)
        link.click()
      },
      removeFile (idFormacion) {
        if (confirm('¿Realmente desea eliminar este registro?')) {
          this.$store.dispatch('formaciones/deleteFile', idFormacion)
            .then((response) => {
              Vue.notify({
                group: 'loggedIn',
                text: response.data.success,
                type: 'teal accent-3',
                duration: 5000,
              })
              this.editedItem.fichero = null
            })
            .catch(err => {
              // eslint-disable-next-line
                console.log(err)
              Vue.notify({
                group: 'loggedIn',
                text: 'Lo sentimos, el documento no puede ser eliminado en este momento',
                type: 'red accent-2',
                duration: 5000,
              })
            },
            )
        }
      },
      submit () {
        if (this.$refs.form.validate()) {
          const formData = new FormData()
          formData.append('index', this.editedIndex)
          formData.append('id', this.editedItem.id)
          formData.append('tipo', this.editedItem.tipo)
          formData.append('fecha', this.editedItem.fecha)
          formData.append('fecha_hasta', this.editedItem.fecha_hasta)
          formData.append('total_horas', this.editedItem.total_horas)
          formData.append('horario_imparticion', this.editedItem.horario_imparticion)
          formData.append('plazas', this.editedItem.plazas)
          /* formData.append('idioma', this.editedItem.idioma) */
          formData.append('descripcion', this.editedItem.descripcion)
          formData.append('_file', this.editedItem.fichero_cargado)

          if (this.editedIndex < 0) {
            this.$store.dispatch('formaciones/addFormaciones', formData)
              .then((response) => {
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.success,
                  type: 'teal accent-3',
                  duration: 5000,
                })
              })
              .catch(err => {
                // eslint-disable-next-line
                  console.log(err)
                Vue.notify({
                  group: 'loggedIn',
                  text: 'No se pudo guardar en este momento',
                  type: 'red accent-2',
                  duration: 5000,
                })
              },
              )
          } else {
            this.$store.dispatch('formaciones/updateFormaciones', formData)
              .then((response) => {
                this.$emit('return-from-form')
                this.$store.commit('formaciones/setRegister', false)
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.success,
                  type: 'teal accent-3',
                  duration: 5000,
                })
                if (response.data.email) {
                  setTimeout(() => {
                    this.$store.dispatch('logout')
                  }, 3000)
                }
              })
              .catch(err => {
                // eslint-disable-next-line
                console.log(err)
                Vue.notify({
                  group: 'loggedIn',
                  text: 'No se pudo guardar en este momento',
                  type: 'red accent-2',
                  duration: 5000,
                })
              },
              )
          }
        }
      },
      addFormacionUsuarias (usuaria) {
        const params = {
          usuariaId: usuaria.id,
          formacionId: this.editedItem.id,
        }
        this.$store
          .dispatch('formaciones/addFormacionUsuaria', params)
          .then(response => {
            Vue.notify({
              group: 'loggedIn',
              text: 'La usuaria se ha asociado a la formación correctamente',
              type: 'teal accent-3',
              duration: 5000,
            })
            this.actualizarListadoDeInscritos(response)
          })
          .catch(error => {
            console.log(error.response.status)
            console.log(error)
            if (error.response.status === 419) {
              Vue.notify({
                group: 'loggedIn',
                text: 'La usuaria ya se encuentra asociada a la formación',
                type: 'orange accent-2',
                duration: 5000,
              })
            }
          })
      },
      removeFormacionUsuaria (usuaria) {
        const params = {
          usuariaId: usuaria.id,
          formacionId: this.editedItem.id,
        }
        this.$store
          .dispatch('formaciones/deleteFormacionUsuaria', params)
          .then(response => {
            Vue.notify({
              group: 'loggedIn',
              text: 'La usuaria se ha removido de la formación',
              type: 'teal accent-3',
              duration: 5000,
            })
            this.actualizarListadoDeInscritos(response)
          })
          .catch(error => {
            console.log(error)
          })
      },
      actualizarListadoDeInscritos (response) {
        const respuesta = response.data.data
        // console.log(respuesta.length)
        this.usuariasInscritas = respuesta.slice(0, this.editedItem.plazas)
        this.usuariasEnEspera = respuesta.slice(this.editedItem.plazas, respuesta.length)
        /* console.log(this.usuariasInscritas)
        console.log(this.usuariasEnEspera) */
      },
      // Metódos relacionados con el listado de usuarias y paginación:
      ordenFecha (value) {
        value === 'ASC' ? (this.sort = 'DESC') : (this.sort = 'ASC')
        this.initialize()
      },
      initialize () {
        const params = this.getRequestParams(
          this.search_usuarias,
          this.page,
          parseInt(this.itemsPerPage),
          this.sort,
        )
        this.$store
          .dispatch('registros/getregistros', params)
          .then(response => {
            // console.log(response)
            this.registros = response.data.items
            this.totalPages = response.data.pages
          })
          .catch(error => {
            console.log(error)
          })
      },
      getRequestParams (search, page, itemsPerPage, sort) {
        const params = {}
        if (search) {
          params.search = search
        }
        if (page) {
          params.page = page
        }
        if (itemsPerPage) {
          params.itemsPerPage = itemsPerPage
        }
        if (sort) {
          params.sort = sort
        }
        return params
      },
      handlePageChange (value) {
        this.page = value
        this.initialize()
      },
      handleitemsPerPageChange (size) {
        this.itemsPerPage = size
        this.page = 1
        this.initialize()
      },
    },
  }
</script>

<style scoped>
.v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}
.v-card__title {
  background-color: aliceblue;
}
.expansionPanel {
  width: 100%;
}
.vue-tel-input {
  height: 50px;
  border: none;
  border-bottom: 1px solid #bbb;
}

.table_add_formacion{
  width: 50%;
  margin: auto;
}

.title_table{
  background-color: #03a9f4;
  color: white;
  text-align: center;
  border-radius: 4px;
}

h2.title_table{
  font-size: 2.4rem;
}

</style>
